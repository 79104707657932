.animate {
    opacity: 0;
}

.animate[data-anim-type="letters"] {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    .fx-word {
        white-space: nowrap;
    }

    .fx-word, .fx-letter {
        transform-origin: 0 100%;
        display: inline-block;
    }
}

.fx-line {
    display: block;
    overflow: hidden;
}

.fx-line-inner {
    display: block;
}
