:root {
   --primary-color: #{$primary-color};

   --dark-bg: #{$dark};
   --dark-color: #c8cfdf;
   --dark-muted: #65718d;
   --dark-highlight: #ffffff;
   --dark-border-color: #36425a;
   --dark-border: 1px solid #36425a;

   --container-padding: 0.75rem;
   --grid-gap: min(36px, 10vw);
   --spacer: 2rem;

   @media (min-width: $sm) {
      --spacer: 4rem;
      --container-padding: 1.5rem;
   }
}
