$font-size-h1: 4.5rem;
$font-size-h2: unquote("min(2.3rem, 10vw)");
$font-size-h3: 1.8rem;
$font-size-h4: 1.4rem;

$serif-font-family: "Neue Machina", serif;
$headings-font-family: "Telegraf", "Space Grotesk", sans-serif;
$headings-font-weight: 400;
$body-font-family: "Telegraf", "Space Grotesk", sans-serif;
$body-font-weight: 400;
$sans-serif-letter-spacing: 0;
$serif-letter-spacing: 0;

$easing: cubic-bezier(0,.62,.26,1);
$transition: 0.60s $easing;
$primary-color: #1281ff;
$dark: #111620;
$error-color: #f55940;
$success-color: #95d84a;

$header-padding-y: 0.4rem;
$header-font-size: 0.875rem;

$icon-size: 1.25em;

$sm: 768px;
