.full-height {
    min-height: 100svh !important;
}

.flex-grow {
    flex-grow: 1 !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mt-n1 {
    margin-top: -0.25em;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-20 {
    margin-top: 8vw !important;
}

.mt-30 {
    margin-top: 12vw !important;
}

.mt-40 {
    margin-top: 16vw !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pt-20 {
    padding-top: 8vw !important;
}

.pb-20 {
    padding-bottom: 8vw !important;
}

.pb-20 {
    padding-bottom: 8vw !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-basis {
    flex: 1 0 0% !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.align-start {
    align-items: flex-start !important;
}

.align-center {
    align-items: center !important;
}

.align-end {
    align-items: flex-end !important;
}

.align-baseline {
    align-items: baseline !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.heading-font {
    font-family: $headings-font-family !important;
}

.ff-serif {
    font-family: $serif-font-family !important;
}

.lh-animate {
    line-height: 1.2 !important;
}

.lh-serif {
    line-height: 0.9 !important;
}

.text-primary {
    color: $primary-color !important;
}

.text-body {
    color: var(--dark-color) !important;
}

.text-highlight {
    color: var(--dark-highlight) !important;
}

.row {
    display: flex;
    gap: var(--grid-gap);
}

.flex-column {
    flex-direction: column !important;
}

@media (min-width: $sm) {
    .flex-row-lg {
        flex-direction: row !important;
    }
}

[class^=col-] {
    flex: 0 0 auto;
}

.col {
    flex: 1 0 0%;
}

@media (min-width: $sm) {
    .col-2 {
        width: 16.66667%;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33.33334%;
    }

    .col-5 {
        width: 41.66667%;
    }

    .col-6 {
        width: 50%;
    }

    .col-7 {
        width: 58.33334%;
    }

    .col-8 {
        width: 66.66667%;
    }
}
