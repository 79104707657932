@font-face {
    font-family: "Neue Machina";
    src: url("/static/fonts/neuemachina/ppneuemachina-inktraplight.woff2") format("woff2"),
         url("/static/fonts/neuemachina/ppneuemachina-inktraplight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Machina";
    src: url("/static/fonts/neuemachina/ppneuemachina-inktrapregular.woff2") format("woff2"),
         url("/static/fonts/neuemachina/ppneuemachina-inktrapregular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Machina";
    src: url("/static/fonts/neuemachina/ppneuemachina-inktrapultrabold.woff2") format("woff2"),
         url("/static/fonts/neuemachina/ppneuemachina-inktrapultrabold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Telegraf";
    src: url("/static/fonts/telegraf/pptelegraf-ultralight.woff2") format("woff2"),
         url("/static/fonts/telegraf/pptelegraf-ultralight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Telegraf";
    src: url("/static/fonts/telegraf/pptelegraf-regular.woff2") format("woff2"),
         url("/static/fonts/telegraf/pptelegraf-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Telegraf";
    src: url("/static/fonts/telegraf/pptelegraf-ultrabold.woff2") format("woff2"),
         url("/static/fonts/telegraf/pptelegraf-ultrabold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
